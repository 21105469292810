nav {
    display: inline-block;
}
nav ul li {
    display: inline-block;
    margin-left: 10px;
}

nav ul li:first-child {
    margin-left: 0;
}

nav ul li a {
    color: white;
}

nav ul li a:hover {
    color: yellow;
}